import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import {Button} from "@mui/material";

const LoginButton = () => {
  const { loginWithRedirect,isAuthenticated } = useAuth0();

  return(
    !isAuthenticated && (
      <Button fullWidth size="large" color="secondary" onClick={() => loginWithRedirect({
        prompt: 'login'
      })}>
        Get Started
      </Button>
    )
  )
};

export default LoginButton;
