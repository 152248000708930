import {lazy, Suspense, useEffect} from 'react';
import {Navigate, useLocation, useNavigate, useRoutes} from 'react-router-dom';

// layouts
import {useAuth0} from '@auth0/auth0-react';
import axios from "axios";
import DashboardLayout from '../layouts/dashboard';
import AuthLayout from '../layouts/AuthLayout';
// guards
import AuthGuard from '../guards/AuthGuard';
import BoardingGuard from '../guards/BoardingGuard';
import LoadingScreen from '../components/LoadingScreen';
import {PATH_AFTER_IF_ONBOARDING, PATH_AFTER_LOGIN} from '../config';
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {pathname} = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')}/>}>
      <Component {...props} />
    </Suspense>
  );
};

const url = process.env.REACT_APP_ENDPOINT_URL;


export default function Router() {

  const {user} = useAuth0();

  // eslint-disable-next-line consistent-return
  async function fetchData() {
    try {
      // Get patientId and role using the userId
      const patientIdResponse = await axios.get(`${url}/.netlify/functions/ExpressAPI/getPatientId`, {params: {userId: user.PatientWebAppUUID}})
      const {role, pregnancyPatientId, spermPartnerId} = patientIdResponse.data;
      let response;
      if (role === 'grossesse_owner') {
        response = await axios.get(`${url}/.netlify/functions/ExpressAPI/getPregnancyOwner`, {params: {pregnancyPatientId}});
      } else {
        response = await axios.get(`${url}/.netlify/functions/ExpressAPI/getSpermPartner`, {params: {spermPartnerId}});
      }
      const data = await response.data;
      const filledInput = data.length ? Object.values(data[0]).filter(val => val != null).length : 0;
      return (data.length > 0 && filledInput >= 4);
    } catch (e) {
      console.error(e);
    }
  }


  function CallbackPage() {
    const {isAuthenticated, isLoading, error, loginWithRedirect} = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
      if (!isLoading) {
        if (isAuthenticated) {
          navigate('/dashboard');
        } else if (error && error.error_description === 'Access denied.') {
          // Redirect to login page
          loginWithRedirect({
            prompt: 'login',
          });
        }
      }
    }, [isLoading, isAuthenticated, error, navigate, loginWithRedirect]);

    return <PageLoading/>;
  }


  return useRoutes([
    {
      path: '/',
      element: !user ? <AuthLayout/> : <Navigate to="/dashboard"/>,
      children: [
        {path: 'login', element: (<Login/>),},
        {path: '/', element: <Navigate to="/login" replace/>},
      ],
    },

    {
      path: '/callback',
      element: <CallbackPage/>,
    },

    {
      path: '/dashboard',
      element: <BoardingGuard isOnboarded={fetchData()}><DashboardLayout/> </BoardingGuard>,
      children: [
        {path: 'main', element: <Dashboard/>},
        {element: <Navigate to={PATH_AFTER_IF_ONBOARDING} replace/>, index: true},
      ]
    },

    {
      path: '/onboarding',
      element: <AuthGuard> <AuthLayout/> </AuthGuard>,
      children: [
        {path: 'main', element: <OnBoarding/>},
        {element: <Navigate to={PATH_AFTER_LOGIN} replace/>, index: true},
      ]
    },

    {
      path: '*',
      element: <LogoOnlyLayout/>,
      children: [
        {path: '404', element: <NotFound/>},
        {path: 'loading', element: <PageLoading/>},
        {path: '*', element: <Navigate to="/404" replace/>},
      ],
    },
  ]);
}
// ----------------------------------------------------------------------
// Auth
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
const OnBoarding = Loadable(lazy(() => import('../pages/OnBoarding')));
const PageLoading = Loadable(lazy(() => import('../pages/PageLoading')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
