import PropTypes from 'prop-types';
// @mui
import { List, Box, Divider } from '@mui/material';
//
import { ListSubheaderStyle } from './style';
import NavList from './NavList';


// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, isCollapse, ...other }) {  
  return (
    <Box {...other}>
      {navConfig.map((group, index) => (        
        <List key={index} disablePadding sx={{ px: 2, my:0, pb:0 }}>          
          <ListSubheaderStyle             
            sx={{...(isCollapse && { opacity: 0}),
              py:0, my:0, 
            }}
          >
            {group.subheader}
          </ListSubheaderStyle>               

          {index > 0 &&
            <Divider sx={{mx:2}}/>
          } 

          {group.items.map((list) => (
            <NavList
              key={list.title + list.path}
              data={list}
              depth={1}
              hasChildren={!!list.children}
              open={list.open}
              isCollapse={isCollapse}
            />
          ))}
        </List>
      ))}
    </Box>
  );
}
