import { pxToRem, responsiveFontSizes } from '../utils/getFontValue';

// ----------------------------------------------------------------------

const FONT_PRIMARY = 'PoppinsRegular, sans-serif';
const FONT_BOLD = 'PoppinsBold, sans-serif';
const FONT_SEMIBOLD = 'PoppinsSemiBold, sans-serif';
const FONT_ITALIC = 'PoppinsItalic, sans-serif'; 
const FONT_THIN = 'PoppinsThin, sans-serif';

const typography =  {
  fontFamily: FONT_PRIMARY,
  fontFamilyBold: FONT_BOLD,
  fontFamilySemiBold: FONT_SEMIBOLD,
  fontFamilyItalic: FONT_ITALIC, 
  fontFamilyThin: FONT_THIN, 
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 1000,
  semibold: {
    fontFamily: FONT_SEMIBOLD,
  },
  h1: {
    fontWeight: 900,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    letterSpacing: 2,
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },
  h2: {
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'capitalize',
  },
  gradient: {
    fontWeight: 1000,
    background: "-webkit-linear-gradient(0deg, rgba(247,181,0,1) 20%, rgba(182,32,224,1) 50%, rgba(50,197,255,1) 80%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  },
  gradientSemiBold: {
    fontFamily: FONT_SEMIBOLD,
    background: "-webkit-linear-gradient(0deg, rgba(247,181,0,1) 20%, rgba(182,32,224,1) 50%, rgba(50,197,255,1) 80%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  }
};

export default typography;
