import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// @mui
import { List, Collapse, Link } from '@mui/material';
import {useAuth0} from "@auth0/auth0-react";
import NavItem from './NavItem';
import { getActive, isExternalLink } from '..';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks

// ----------------------------------------------------------------------

NavList.propTypes = {
  data: PropTypes.object,
  depth: PropTypes.number,
  hasChildren: PropTypes.bool,
  isCollapse: PropTypes.bool,
};

export default function NavList({ data, depth, hasChildren, isCollapse = false }) {
  const navigate = useNavigate();

  const { logout, } = useAuth0()

  const { enqueueSnackbar } = useSnackbar();

  const { pathname } = useLocation();

  const active = getActive(data.path, pathname);

  const [open, setOpen] = useState(data.open);

  const handleLogout = async () => {
    await logout({ returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL});
    enqueueSnackbar('Logged out!', { variant: 'success' });
    navigate(PATH_AUTH.login);
  };

  const handleClickItem = () => {
    if (data.title === "Logout"){
      handleLogout()
    }else{
      if (!hasChildren) {
        navigate(data.path);
      }
      setOpen(!open);
    }    
  };

  return (
    < >      
      {isExternalLink(data.path) ? (
        <Link href={data.path} target="_blank" rel="noopener" underline="none">
          <NavItem item={data} depth={depth} open={open} active={active} isCollapse={isCollapse} />
        </Link>
      ) : (
        <NavItem 
          item={data}
          depth={depth}
          open={open}
          active={active}
          isCollapse={isCollapse}
          onClick={handleClickItem}
        />
      )}

      {!isCollapse && hasChildren && (
        <Collapse in={open} unmountOnExit>
          <List component="div" disablePadding>
            <NavSubList data={data.children} depth={depth} />
          </List>
        </Collapse>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

NavSubList.propTypes = {
  data: PropTypes.array,
  depth: PropTypes.number,
};

function NavSubList({ data, depth }) {
  return (
    <>
      {data.map((list) => (
        <NavList sx={{backgroundColor: '#f90000' }} key={list.title + list.path} data={list} depth={depth + 1} hasChildren={!!list.children} />
      ))}
    </>
  );
}
