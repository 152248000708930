import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// components
import { BaseOptionChart } from '.';

// ----------------------------------------------------------------------

SemiCircularChart.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  height: PropTypes.string,
};

export default function SemiCircularChart({ chartData, height, ...other }) {
  const chartOptions = merge(BaseOptionChart(), {
    series: chartData,
    legend: {
      show: false
    },
    labels: [],
    chart: {
      type: 'radialBar',
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -120,
        endAngle: 120,
        track: {
          background: "#e7e7e7",
          strokeWidth: '97%',
          margin: 5, 
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            offsetY: -0.5,
            fontSize: '13px'
          }
        }
      },
    },
    grid: {
      padding: {
        top: 5,
        bottom: 5
      }
    },
    fill: {
      type: 'gradient',      
      gradient: {
        type: 'horizontal',
        shadeIntensity: 0.5,
        colorStops: [
          {
            offset: 0,
            color: "#F7B500",
            opacity: 1
          },
          {
            offset: 50,
            color: "#B620E0",
            opacity: 1
          },
          {
            offset: 100,
            color: "#32C5FF",
            opacity: 1
          },
        ],
        inverseColors: true,
      }
    },
  });

  return (
    <ReactApexChart type="radialBar" series={chartData} options={chartOptions} height={height} sx={{ ...other}} />
  )
}
