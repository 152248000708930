import _mock from './_mock';
import { randomInArray } from './funcs';

// ----------------------------------------------------------------------

export const _bookings = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  avatar: _mock.image.avatar(index),
  checkIn: _mock.time(index),
  checkOut: _mock.time(index),
  phoneNumber: _mock.phoneNumber(index),
  status: randomInArray(['pending', 'un_paid', 'paid']),
  roomType: randomInArray(['double', 'king', 'single']),
}));

export const _bookingsOverview = [...Array(6)].map((_, index) => ({
  status: ['pre-admission', 'first appointment', 'pending treatment', 'ongoing treatment', 'positive outcome', 'negative outcome'][index],
  quantity: _mock.number.percent(index) * 1000,
  value: _mock.number.percent(index),
}));

export const _bookingReview = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  description: _mock.text.description(index),
  avatar: _mock.image.avatar(index),
  rating: _mock.number.rating(index),
  postedAt: _mock.time(index),
  tags: ['Great Sevice', 'Recommended', 'Best Price'],
}));

const _notes = [...Array(2)].map((_, index) => ({
  id: _mock.id(index),
  docteurName: _mock.name.fullName(index),
  date: _mock.time(index),
  description: _mock.text.description(index)  
}));

export const _patientDetail = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index), 
  avatar: _mock.image.avatar(index),
  hour: _mock.time(index),
  status: randomInArray(['pre-admission', 'first appointment', 'pending treatment', 'ongoing treatment', 'positive outcome', 'negative outcome']),
  typeInfertility:  randomInArray(['Primary', 'Secondary']),
  sourceinfertility:  randomInArray(['Male factor','Female factor', 'Male and Female factor', 'Idiopathic']),
  notes : _notes
}));
