import { memo } from 'react';

// ----------------------------------------------------------------------

function GradientSVG() {

  return (
  <svg width="0" height="0">
      <linearGradient id="gradient" x1="100%" y1="100%" x2="0%" y2="0%">
        <stop stopColor="#F7B500" offset="0%" />
        <stop stopColor="#B620E0" offset="50%" />
        <stop stopColor="#32C5FF" offset="100%" />
      </linearGradient>
    </svg>
  );
}

export default memo(GradientSVG);
