import _mock from './_mock';

import { randomInArray } from './funcs';

export const _alertLastFive = [...Array(5)].map((_, index) => ({
    id: _mock.id(index),
    title: "Lorem Ipsum",
    type: randomInArray(['error', 'warning', 'info', 'success']),
    description: "Lorem Ipsum is simply dummy text.",
    date: _mock.time(index),
}));