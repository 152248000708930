import { Outlet } from 'react-router-dom';
// @mui
// ----------------------------------------------------------------------

export default function AuthLayout() {
  return (
    <>
      <Outlet />
    </>
  );
}
