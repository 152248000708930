import { add } from 'date-fns';
import _mock from './_mock';
import { randomInArray } from './funcs';

// ----------------------------------------------------------------------

export const _patients = [...Array(20)].map((_, index) => ({
  id: _mock.id(index),
  lastConsultDate: add(new Date(), { days: index, hours: index }),
  nextConsultDate: add(new Date(), { days: index + 15, hours: index }),
  status: randomInArray(['pre-admission', 'first appointment', 'pending treatment', 'ongoing treatment', 'positive outcome', 'negative outcome']),
  patientFemale: {
    id: _mock.id(index),
    name: _mock.name.fullName(index),
  },
  patientMale: {
    id: _mock.id(index),
    name: _mock.name.fullName(index),
  },
  alert:{
    type:_mock.boolean(index),
    description: "there's a problem"
  },
  treatmentStep: randomInArray([
    'ovarian stimulation',
    'egg retrieval',
    'sperm retrieval',
    'fertilization',
    'embryo transfer',
  ]),
  ongoingAttempt:{
    nbAttempt: _mock.number.age(index),
    attemptType: randomInArray([
      'exploration',
      'IVF',
      'IUI',
      'ICSI',
      'GIFT',
    ])    
  }
}));
