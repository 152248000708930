import SvgIcon from '@mui/material/SvgIcon';
import LogoutIcon from '@mui/icons-material/Logout';
import { Avatar } from '@mui/material';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';


// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const getIconMui = (name) => <SvgIcon component={name} sx={{ width: 1, height: 1 }} />

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  logout: getIconMui(LogoutIcon),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      { title: 'Dashboard', path: '/dashboard/main', icon: ICONS.dashboard },
      { title: 'Profil', path: '#profil', icon: <Avatar src="https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_5.jpg" sx={{ mr: 1, padding: 0.5, borderRadius: '100%' }}alt="Rayan Moran" /> },
      { title: 'Logout', path: '', icon: ICONS.logout },
    ],
  },
];

export default navConfig;
