import PropTypes from 'prop-types';
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {useAuth0} from "@auth0/auth0-react";


BoardingGuard.propTypes = {
    children: PropTypes.node,
    isOnboarded: PropTypes.object
};


export default function BoardingGuard({ children,isOnboarded}) {
    const [authorized, setAuthorized] = useState(false);
    const navigate = useNavigate()
    const {user} = useAuth0();

    isOnboarded.then((
        value => {
            if (value === true) {
                setAuthorized(true);
            }else{
                navigate('/onboarding');
            }
        }
    ))
    if (authorized && user) {
        return children;
    }
    return null;
    
}


